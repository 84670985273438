import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import AsideNav from './AsideNav';
import Auftragssuche from './Auftragssuche';

const NavItem = props => (
  <li>
    <NavLink
      to={props.href}
      className={`${styles.link} d-flex align-items-center h-100 text-white px-4`}
    >
      {props.children}
    </NavLink>
  </li>
);

const Header = props => (
  <header
    className={`${styles.header} bg-brand font-weight-bold text-white position-relative`}
  >
    <div className="container">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <nav className="d-flex align-items-stretch">
            <div className={styles.productName}>Legal</div>
            <ul className="list-unstyled p-0 d-flex mr-5 mb-0">
              <NavItem href="/dashboard">Dashboard</NavItem>
            </ul>
            <Auftragssuche />
          </nav>
        </div>

        <AsideNav />
      </div>
    </div>
  </header>
);

export default Header;
