const tempingURI = process.env.REACT_APP_TEMPING_URI || 'http://localhost:3000';

const auftragEndpoint = auftragsnummer => {
  const url = new URL(tempingURI);
  url.pathname = `/orders/${auftragsnummer}`;

  return url.href;
};

export default {
  auftragEndpoint
};
