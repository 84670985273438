import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import AlertContext from '../../../Alert/Context';
const ErstelleNeueVersionDesDokumentesMutation = loader(
  './ErstelleNeueVersionDesDokumentesMutation.graphql'
);
const AuftragsdetailsQuery = loader('../../AuftragsdetailsQuery.graphql');

export default ({ auftragsnummer, dokumentId }) => {
  const [erstelleNeueVersion, { loading }] = useMutation(
    ErstelleNeueVersionDesDokumentesMutation,
    {
      update(cache, { data: { erstelleNeueVersionDesDokumentes } }) {
        cache.writeQuery({
          query: AuftragsdetailsQuery,
          variables: {
            auftragsnummer: parseInt(auftragsnummer),
          },
          data: { auftragsdetails: erstelleNeueVersionDesDokumentes },
        });
      },
    }
  );

  const { showAlert } = useContext(AlertContext);

  const handleClick = (event) => {
    erstelleNeueVersion({ variables: { dokumentId } })
      .then(({ data }) => {
        showAlert({
          message: 'Eine neue Version wurde erstellt.',
          type: 'success',
        });
      })
      .catch(({ error }) => {
        showAlert({
          message: 'Die neue Version konnte nicht erstellt werden.',
          type: 'danger',
        });
      });
  };

  return (
    <button
      disabled={loading}
      className="btn btn-sm btn-primary"
      type="button"
      onClick={handleClick}
    >
      Neue Version erstellen
    </button>
  );
};
