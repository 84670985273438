const zendeskSellURI =
  process.env.REACT_APP_ZENDESK_SELL_URI || 'http://localhost:3000';

const contactEndpoint = zendeskSellID => {
  const url = new URL(zendeskSellURI);
  url.pathname = `/crm/contacts/${zendeskSellID}`;

  return url.href;
};

export default {
  contactEndpoint
};
