import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import LoadingScreen from '../LoadingScreen';
import Details from './Details';
import AuevVerweis from './AuevVerweis';
import Dokumente from './Dokumente';
import Aenderungen from './Aenderungen';
import WeitereAuftraege from './WeitereAuftraege';
import { Link } from 'react-router-dom';
import subscribeToMoreCaller from './subscribeToMoreCaller';
import Temping from '../../lib/Temping';
import { fromISO8601 as intervalFromISO8601 } from '../../lib/Interval';

const AuftragsdetailsQuery = loader('./AuftragsdetailsQuery.graphql');

export default (props) => {
  const { data, loading, error, subscribeToMore } = useQuery(
    AuftragsdetailsQuery,
    {
      variables: {
        auftragsnummer: parseInt(props.match.params.auftragsnummer),
      },
    }
  );

  const auftragIsActive = (vertragszeitraum) => {
    const { end } = intervalFromISO8601(vertragszeitraum);

    return end >= Date.now();
  };

  useEffect(() => {
    subscribeToMoreCaller(
      subscribeToMore,
      parseInt(props.match.params.auftragsnummer)
    );
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error :(</p>;
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-end mb-6">
        <h1 className="h1">Auftrag {data.auftragsdetails.auftragsnummer}</h1>
        <span>
          <Link to="/dashboard" className="mr-6">
            zurück zum Dashboard
          </Link>
          <a
            href={Temping.auftragEndpoint(data.auftragsdetails.auftragsnummer)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Auftrag in Staffing anzeigen
          </a>
        </span>
      </div>
      <div className="row">
        <div className="col-12 col-xl-6">
          <section className="mb-5">
            <Details auftragsdetails={data.auftragsdetails} />
          </section>
          <section className="mb-5">
            <Aenderungen aenderungen={data.auftragsdetails.aenderungen} />
          </section>
        </div>
        <div className="col-12 col-xl-6">
          <section className="mb-5">
            <WeitereAuftraege auftragsdetails={data.auftragsdetails} />
          </section>
          <section className="mb-5">
            <AuevVerweis auftragsdetails={data.auftragsdetails} />
          </section>
          <section>
            <Dokumente
              dokumente={data.auftragsdetails.dokumente}
              auftragsnummer={data.auftragsdetails.auftragsnummer}
              uevVertragsnummer={data.auftragsdetails.uev.vertragsnummer}
              auevVertragsnummer={data.auftragsdetails.auevVertragsnummer}
              malVertragsnummer={data.auftragsdetails.malVertragsnummer}
              zendeskSellId={data.auftragsdetails.kunde.zendeskSellId}
              auftragIsActive={auftragIsActive(
                data.auftragsdetails.vertragszeitraum
              )}
            />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};
