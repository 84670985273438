import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authenticationSession from '../../lib/authenticationSession';

const PrivateRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={innerProps =>
        authenticationSession.isLoggedIn ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: props.location.pathname }
            }}
          />
        )
      }
    />
  );
};

export default ({
  component: Component,
  isPrivate = true,
  layout: Layout,
  ...props
}) => {
  const customRoute = isPrivate ? (
    <PrivateRoute component={Component} {...props} />
  ) : (
    <Route component={Component} {...props} />
  );

  return !!Layout ? <Layout>{customRoute}</Layout> : customRoute;
};
