const fromISO8601 = interval => {
  let start, end;
  [start, end] = interval
    .split('/')
    .map(d => (d && new window.Date(d)) || null);

  return {
    start,
    end
  };
};

export { fromISO8601 };
