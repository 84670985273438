import authenticationSession from '../authenticationSession';

export default (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authParams = authenticationSession.getAuthParams();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(authParams || {})
    }
  };
};
