import React from 'react';
import { format } from 'date-fns';
import { formatDate } from '../../lib/Localization';
import PropTypes from 'prop-types';

const Date = ({ date }) => {
  const isoDate = format(date, 'yyyy-MM-dd');
  return <time dateTime={isoDate}>{formatDate(date)}</time>;
};
Date.propTypes = {
  date: PropTypes.instanceOf(window.Date).isRequired
};

export default Date;
