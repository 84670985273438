import { loader } from 'graphql.macro';
const DashboardItemsQuery = loader('./DashboardItemsQuery.graphql');

export default (fetchMore, data) => {
  fetchMore({
    // note this is a different query than the one used in the
    // Query component
    query: DashboardItemsQuery,
    variables: { cursor: data.dashboardItems.pageInfo.endCursor },
    updateQuery: (prev, { fetchMoreResult }) => {
      const newEdges = fetchMoreResult.dashboardItems.edges;
      const pageInfo = fetchMoreResult.dashboardItems.pageInfo;

      return newEdges.length
        ? {
            // Put the new dashboardItems at the end of the list and update
            // `pageInfo` so we have the new `endCursor` and `hasNextPage`
            // values
            dashboardItems: {
              __typename: prev.dashboardItems.__typename,
              edges: [...prev.dashboardItems.edges, ...newEdges],
              pageInfo
            }
          }
        : prev;
    }
  });
};
