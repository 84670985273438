import React from 'react';
import styles from './ActionCard.module.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const navigate = ({ history, href }) => {
  history.push(href);
};

const ActionCard = props => (
  <div
    onClick={navigate.bind(null, { history: props.history, href: props.href })}
    className={`${styles.actionCard} d-flex bg-white p-3 rounded-lg border`}
  >
    {props.children}
  </div>
);

ActionCard.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default withRouter(ActionCard);
