import React from 'react';
import Zuschlag from './Zuschlag';

export default props => {
  if (props.zuschlaege.length === 0) {
    return 'Ohne Zuschläge';
  } else {
    return (
      <ul className="list-unstyled custom-styles-list-indicator mb-n3">
        {props.zuschlaege.map((zuschlag, index) => (
          <li className="mb-3" key={index}>
            <Zuschlag zuschlag={zuschlag} />
          </li>
        ))}
      </ul>
    );
  }
};
