const legalURI = process.env.REACT_APP_LEGAL_API_URI || 'http://localhost:3000';

const graphqlEndpoint = () => {
  const url = new URL(legalURI);
  url.pathname = '/graphql';

  return url.href;
};

const dokumenteEndpoint = uuid => {
  const url = new URL(legalURI);
  url.pathname = `/dokumente/${uuid}`;

  return url.href;
};

const actioncableEndpoint = () => {
  const url = new URL(legalURI);
  url.pathname = `/cable`;

  return url.href;
};

const authEndpoint = () => {
  const url = new URL(legalURI);
  url.pathname = `/auth/request`;

  return url.href;
};

const handleAuthentication = response => {
  if (response.status === 200) {
    return Promise.resolve({
      client: response.headers.get('client'),
      'access-token': response.headers.get('access-token'),
      expiry: response.headers.get('expiry'),
      uid: response.headers.get('uid')
    });
  } else {
    console.error(response);
    return Promise.reject(new Error('Authentication failed.'));
  }
};

const authenticateOptions = googleAuthToken => {
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${googleAuthToken}`,
      'Content-Type': 'application/json'
    }
  };
};

const authenticate = googleAuthToken => {
  return fetch(authEndpoint(), authenticateOptions(googleAuthToken)).then(
    handleAuthentication
  );
};

export default {
  graphqlEndpoint,
  dokumenteEndpoint,
  actioncableEndpoint,
  authEndpoint,
  authenticate
};
