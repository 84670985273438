const kundenportalURI =
  process.env.REACT_APP_KUNDENPORTAL_URI || 'http://localhost:3000';

const vertragsverwaltungVertragssucheEndpoint = ({
  vertragstyp,
  auftragsnummer,
  auevVertragsnummer,
  uevVertragsnummer
}) => {
  if (["auev", "uev"].includes(vertragstyp)) { 
    const url = new URL(kundenportalURI);
    url.pathname = `/vertraege/admin/suche`;

    url.searchParams.set('auftragsnummer', auftragsnummer);
    url.searchParams.set('typ', vertragstyp);

    if (vertragstyp === 'uev') {
      url.searchParams.set('vertragsnummer', uevVertragsnummer);
    }

    if (vertragstyp === 'auev') {
      url.searchParams.set('vertragsnummer', auevVertragsnummer);
    }

    return url.href;
  }
};


export default {
  vertragsverwaltungVertragssucheEndpoint
};
