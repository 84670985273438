import React from 'react';
import authenticationSession from '../../lib/authenticationSession';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const logout = () => authenticationSession.logout();

export default () => {
  return (
    <button onClick={logout}>
      <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
      Logout
    </button>
  );
};
