const initParams = {
  client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  cookie_policy: 'single_host_origin',
  scope: 'profile email',
  access_type: 'online',
  ux_mode: 'popup'
};

const signInParams = {
  prompt: ''
};

const fetchUserData = () =>
  new Promise((resolve, reject) => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init(initParams).then(googleAuth => {
        googleAuth.signIn(signInParams).then(resolve, reject);
      });
    });
  });


const extractAccessToken = response => response.getAuthResponse().access_token;

const getAccessToken = () => fetchUserData().then(extractAccessToken);

export default {
  getAccessToken
};
