import React from 'react';
import Card from '../Card';
import map from '../../lib/DetailsMapper';
import DefinitionGroup from './DefinitionGroup';

export default props => {
  const auftragsdetails = map(props.auftragsdetails);

  return (
    <Card>
      <h2 className="h2 mb-6">Details</h2>
      <dl className="mb-n5">
        <DefinitionGroup term="Kunde" definition={auftragsdetails.kundenname} />
        <DefinitionGroup
          term="Zendesk Sell ID"
          definition={auftragsdetails.zendeskSellId}
        />
        <DefinitionGroup
          term="Vertragsadresse"
          definition={auftragsdetails.vertragsadresse}
        />
        <DefinitionGroup
          term="Einsatzort"
          definition={auftragsdetails.einsatzort}
        />
        <DefinitionGroup
          term="Vertragszeitraum"
          definition={auftragsdetails.vertragszeitraum}
        />
        <DefinitionGroup
          term="Entgeltgruppe"
          definition={auftragsdetails.entgeltgruppe_for_display}
        />
        <DefinitionGroup
          term="Taetigkeitsqualifikation"
          definition={auftragsdetails.taetigkeitsqualifikation_for_display}
        />
        <DefinitionGroup
          term="Einsatzzeitraum"
          definition={auftragsdetails.einsatzzeitraum}
        />
        <DefinitionGroup
          term="Tätigkeit"
          definition={auftragsdetails.taetigkeit}
        />
        <DefinitionGroup
          term="Anzahl der Mitarbeiter"
          definition={auftragsdetails.anzahlDerMitarbeiter}
        />
        <DefinitionGroup
          term="Verrechnungssätze"
          definition={auftragsdetails.verrechnungssaetze}
        />
        <DefinitionGroup
          term="Zuschlagssätze"
          definition={auftragsdetails.zuschlagssaetze}
        />
        <DefinitionGroup
          term="Schwellwerte für Mehrarbeitszuschlag"
          definition={auftragsdetails.schwellwerteFuerMehrarbeitszuschlag}
        />
        <DefinitionGroup
          term="Zahlungsziele"
          definition={auftragsdetails.zahlungsziele}
        />
        <DefinitionGroup
          term="Rechnungsturnusse"
          definition={auftragsdetails.rechnungsturnusse}
        />
      </dl>
    </Card>
  );
};
