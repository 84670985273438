import React from 'react';
import Date from '../Date';
import PropTypes from 'prop-types';

const Interval = ({ start, end }) => (
  <React.Fragment>
    {start ? <Date date={start} /> : 'unbekannt'}
    &ndash;
    {end ? <Date date={end} /> : 'unbekannt'}
  </React.Fragment>
);

Interval.propTypes = {
  start: PropTypes.instanceOf(window.Date),
  end: PropTypes.instanceOf(window.Date)
};

export default Interval;
