import { useEffect } from 'react';
import load from 'load-script';

const GOOGLE_API_SCRIPT_URI = 'https://apis.google.com/js/api.js';

export default props => {
  useEffect(() => {
    load(GOOGLE_API_SCRIPT_URI, () => {});
  }, []);
  return null;
};
