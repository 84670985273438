import React from 'react';
// import GoogleLogin from 'react-google-login';
import GoogleAPIScript from '../GoogleAPIScript';
import authenticationSession from '../../lib/authenticationSession';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.redirectURI = this.redirectURI.bind(this);
    this.state = {
      authenticationFailed: false,
    };
  }

  login() {
    return authenticationSession
      .login()
      .catch(this.handleError)
      .then(this.handleAuthentication);
  }

  handleAuthentication() {
    this.props.history.push(this.redirectURI());
  }

  handleError(error) {
    this.setState({
      authenticationFailed: true,
    });
  }

  redirectURI() {
    return (
      (this.props.location.state && this.props.location.state.referrer) || '/'
    );
  }

  render() {
    return (
      <React.Fragment>
        <GoogleAPIScript />
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="d-flex flex-column pb-9">
            <img
              src={process.env.PUBLIC_URL + '/images/jobvalley-logo.svg'}
              height="40"
              alt="jobvalley"
              className="mb-6"
            ></img>
            <button
              type="button"
              className="btn btn-lg btn-cta btn-block"
              onClick={this.login}
            >
              Mit Google anmelden
            </button>
            {this.state.authenticationFailed && (
              <div className="alert alert-danger mt-3" role="alert">
                Do küss he net erin!
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
