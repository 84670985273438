import React, { useState } from 'react';
import TempingVerweisForm from './Temping/TempingVerweisForm';
import KissVerweisForm from './Kiss/KissVerweisForm';
import FremdvertragVerweisForm from './Fremdvertrag/FremdvertragVerweisForm';
import YoungProfessionalsVerweisForm from './YoungProfessionals/YoungProfessionalsVerweisForm';

const VerweisForm = ({ verweisart, auftragsnummer }) => {
  switch (verweisart) {
    case 'kiss':
      return <KissVerweisForm auftragsnummer={auftragsnummer} />;
    case 'fremdvertrag':
      return <FremdvertragVerweisForm auftragsnummer={auftragsnummer} />;
    case 'youngProfessionals':
      return <YoungProfessionalsVerweisForm auftragsnummer={auftragsnummer} />;
    default:
      return <TempingVerweisForm auftragsnummer={auftragsnummer} />;
  }
};

export default ({ auftragsnummer }) => {
  const verweisartOptions = [
    {
      name: 'Staffing-AÜV aus anderem Auftrag',
      value: 'temping',
    },
    { name: 'KISS-AÜV', value: 'kiss' },
    { name: 'Fremdvertrag', value: 'fremdvertrag' },
    { name: 'Young–Professionals-Vertrag', value: 'youngProfessionals' },
  ];

  const verweisartDefault = verweisartOptions[0].value;

  const [state, setState] = useState({
    verweisart: verweisartDefault,
  });

  const onChange = (event) => {
    event.preventDefault();

    setState({
      verweisart: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <div className="d-inline-flex flex-column align-items-start">
        <form>
          <h3 className="mb-4">Verweis anpassen</h3>
          <div className="form-group">
            <label htmlFor="verweis_form_verweisart">Art des Verweises</label>
            <select
              id="verweis_form_verweisart"
              className="custom-select w-auto d-block"
              value={state.verweisart}
              onChange={onChange}
              required
            >
              {verweisartOptions.map(({ name, value }) => (
                <option value={value} key={`verweisart-${value}`}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </form>
        <VerweisForm
          verweisart={state.verweisart}
          auftragsnummer={auftragsnummer}
        />
      </div>
    </React.Fragment>
  );
};
