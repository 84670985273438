import React from 'react';
import PropTypes from 'prop-types';

const ZeileMitName = ({ name }) => {
  if (!name) return null;

  if (name) {
    return (
      <React.Fragment>
        {name}
        <br />
      </React.Fragment>
    );
  }
};

const ZeileMitStrasse = ({ strasse, hausnummer }) => {
  if (!strasse) return null;

  return (
    <React.Fragment>
      {strasse} {hausnummer}
      <br />
    </React.Fragment>
  );
};

const Address = props => {
  if (!props.plz || !props.stadt) return null;

  return (
    <address>
      <ZeileMitName name={props.name} />
      <ZeileMitStrasse strasse={props.strasse} hausnummer={props.hausnummer} />
      {props.plz} {props.stadt}
    </address>
  );
};

Address.propTypes = {
  name: PropTypes.string,
  strasse: PropTypes.string,
  hausnummer: PropTypes.string,
  plz: PropTypes.string,
  stadt: PropTypes.string
};

export default Address;
