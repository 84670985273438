import React from 'react';
import { formatDateTime } from '../../lib/Localization';
import map from '../../lib/DetailsMapper';
import styles from './Aenderung.module.scss';
import PropTypes from 'prop-types';

const mapDataToComponents = data => {
  const key = Object.keys(data)[0]; // i.e.: `verrechnungssaetze`
  return map(data)[key];
};

const Jetzt = ({ data }) => (
  <div className={`p-3 ${styles.vorherContent}`}>
    <div className="text-success mb-3">Neu</div>
    {mapDataToComponents(data) || '-'}
  </div>
);

const Vorher = ({ data }) => (
  <div className={`p-3 ${styles.jetztContent}`}>
    <div className="text-danger mb-3">Alt</div>
    {mapDataToComponents(data) || '-'}
  </div>
);

const Aenderung = ({ aenderung, showDetails }) => {
  const hasChanges = Object.keys(aenderung.jetzt).length !== 0;
  return (
    <div className="text-monospace custom-styles-font-size-sm">
      <div className="mb-2">
        <div className="row">
          <span className="col-auto text-nowrap text-black">
            {formatDateTime(new window.Date(aenderung.geaendertAm))}
          </span>
          <span className="col">{aenderung.name}</span>
        </div>
      </div>
      {showDetails ? (
        <React.Fragment>
          <div className="mb-2">
            {aenderung.geaendertVon ? (
              <span className="text-black">von {aenderung.geaendertVon}</span>
            ) : null}
          </div>
          {hasChanges ? (
            <React.Fragment>
              {aenderung.jetzt ? <Jetzt data={aenderung.jetzt} /> : null}
              {aenderung.vorher ? <Vorher data={aenderung.vorher} /> : null}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  );
};

Aenderung.propTypes = {
  aenderung: PropTypes.shape({
    name: PropTypes.string.isRequired,
    geaendertAm: PropTypes.string.isRequired,
    geaendertVon: PropTypes.string,
    jetzt: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    vorher: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }).isRequired,
  showDetails: PropTypes.bool
};

export default Aenderung;
