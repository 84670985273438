/**
 * Security concerns:
 * We store authentication-data in local-storage. Even though it is encrypted
 * by AES, the secret-key for decryption is visible in the JS source code.
 * Minifing the JS file makes it less obvious, but it is still a potential risk.
 *
 * One alternative would be to store authentication-data in a http-only-cookie,
 * handled by the server, so the data is not accessable via Javascript.
 *
 * Still an XSS-attacker could make server-requests in behalf of the user and
 * access all the data anyways.
 */

import GoogleAPI from '../GoogleAPI';
import LegalAPI from '../LegalAPI';
import apolloClient from '../apolloClient';
import ls from '../secureLs';

class Authentication {
  constructor() {
    if (this.getPersistedAuthParams()) {
      this.isLoggedIn = true;
      this.authParams = this.getPersistedAuthParams();
    } else {
      this.isLoggedIn = false;
      this.authParams = null;
    }
  }

  getAuthParams() {
    return this.authParams;
  }

  handleAuthentication(response) {
    this.authParams = response;
    this.isLoggedIn = true;
    this.persistAuthParams();
  }

  getPersistedAuthParams() {
    try {
      return ls.get('auth');
    } catch {
      ls.remove('auth');
      throw new Error('Error while reading auth data');
    }
  }

  persistAuthParams() {
    ls.set('auth', this.authParams);
  }

  login() {
    return GoogleAPI.getAccessToken()
      .then(LegalAPI.authenticate)
      .then(this.handleAuthentication.bind(this));
  }

  logout() {
    this.isLoggedIn = false;
    this.authParams = null;
    ls.remove('auth');
    apolloClient.clearStore();
    window.location.href = '/';
  }
}

export default new Authentication();
