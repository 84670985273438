import React, { useState } from 'react';
import Aenderung from '../Aenderung';
import Card from '../Card';

const namenAusgeblendeterAenderungen = ['Student in Schicht disponiert', 'Student aus Schicht entfernt']

export default ({ aenderungen }) => {
  const [state, setState] = useState({
    showDetails: false
  });

  const changeHandler = event => {
    setState({
      showDetails: event.target.checked
    });
  };

  return (
    <Card>
      <div className="mb-6 d-flex justify-content-between align-items-baseline">
        <h2 className="h2 mb-0">Änderungen</h2>
        {aenderungen.length > 0 ? (
          <div className="custom-control custom-switch">
            <input
              value={state.showDetails}
              type="checkbox"
              className="custom-control-input"
              id="aenderungen_details_toggle"
              onChange={changeHandler}
            />
            <label
              className="custom-control-label"
              htmlFor="aenderungen_details_toggle"
            >
              Details einblenden
            </label>
          </div>
        ) : null}
      </div>
      {aenderungen.length > 0 ? (
        <ul
          className={`list-unstyled ${state.showDetails ? 'mb-n5' : 'mb-n2'}`}
        >
          {aenderungen.filter(aenderung => !namenAusgeblendeterAenderungen.includes(aenderung.name)).map((aenderung, index) => (
            <li className={state.showDetails ? 'mb-5' : 'mb-2'} key={index}>
              <Aenderung
                aenderung={aenderung}
                showDetails={state.showDetails}
              />
            </li>
          )
        )}
        </ul>
      ) : (
        'keine'
      )}
    </Card>
  );
};
