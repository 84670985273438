import React from 'react';
import PropTypes from 'prop-types';

const Card = props => (
  <div className="bg-white p-5 pb-6 w-100 rounded-lg border">
    {props.children}
  </div>
);

Card.propTypes = {
  children: PropTypes.any.isRequired
};

export default Card;
