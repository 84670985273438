import React from 'react';
import Header from '../Header';
import AlertProvider from '../Alert/Provider';
import Alert from '../Alert';

export default props => {
  return (
    <AlertProvider>
      <div className="sticky-top">
        <Header />
        <Alert />
      </div>
      <main className="container pt-6 pb-4">{props.children}</main>
    </AlertProvider>
  );
};
