import { loader } from 'graphql.macro';
const AuftragsdetailsChangedSubscription = loader(
  './AuftragsdetailsChangedSubscription.graphql'
);

export default (subscribeToMore, auftragsnummer) => {
  subscribeToMore({
    document: AuftragsdetailsChangedSubscription,
    variables: { auftragsnummer },
    updateQuery(prev, { subscriptionData }) {
      if (!subscriptionData.data) return prev;

      const changedAuftragsdetails =
        subscriptionData.data.auftragsdetailsChanged;

      return Object.assign({}, prev, {
        auftragsdetails: {
          ...changedAuftragsdetails
        }
      });
    }
  });
};
