import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import LoadingScreen from '../LoadingScreen';
import LoadingIndicator from '../LoadingIndicator';
import DashboardItem from './DashboardItem';
import subscribeToMoreCaller from './subscribeToMoreCaller';
import fetchMoreCaller from './fetchMoreCaller';
import InfiniteScroll from 'react-infinite-scroll-component';

const DashboardItemsQuery = loader('./DashboardItemsQuery.graphql');

const namenAusgeblendeterAenderungen = ['Student in Schicht disponiert', 'Student aus Schicht entfernt']

const Dashboard = () => {
  const { data, loading, error, subscribeToMore, fetchMore } = useQuery(
    DashboardItemsQuery
  );


  useEffect(() => {
    subscribeToMoreCaller(subscribeToMore);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  if (loading) return <LoadingScreen />;
  if (error) return <p>Error :(</p>;

  const dashboardItems = data.dashboardItems.edges.map(edge => edge.node);
  const dashboardItemsMitGefiltertenAenderungen = dashboardItems.map(dashboardItem => {
    dashboardItem.aenderungen = dashboardItem.aenderungen.filter(aenderung => !namenAusgeblendeterAenderungen.includes(aenderung.name))
    return dashboardItem
  })
  const sanitizedDashboardItems = dashboardItemsMitGefiltertenAenderungen.filter(dashboardItemMitGefiltertenAenderungen => dashboardItemMitGefiltertenAenderungen.aenderungen.length > 0)

  return (
    <InfiniteScroll
      dataLength={data.dashboardItems.edges.length}
      next={fetchMoreCaller.bind(null, fetchMore, data)}
      hasMore={data.dashboardItems.pageInfo.hasNextPage}
      loader={
        <div className="d-flex justify-content-center">
          <LoadingIndicator />
        </div>
      }
      style={{ overflow: 'visible' }}
    >
      {sanitizedDashboardItems.map((dashboardItem, index) => (
        <div className="mb-5" key={index}>
          <DashboardItem dashboardItem={dashboardItem} />
        </div>
      ))}
    </InfiniteScroll>
  );
};

export default Dashboard;
