import React from 'react';
import Card from '../../Card';
import VerweisAnpassen from './VerweisAnpassen';
import { formatDate } from '../../../lib/Localization';
import { Link } from 'react-router-dom';

const AktuellerVerweis = ({ auftragsnummer, rahmenvertrag }) => {
  let text;
  const vertragsart = rahmenvertrag.vertragsart;

  if (
    vertragsart === 'auev' &&
    auftragsnummer.toString() === rahmenvertrag.auev.auftragsnummer.toString()
  ) {
    text = 'Dieser Auftrag hat eine eigene Staffing-AÜV.';
  } else if (vertragsart === 'auev') {
    text = (
      <React.Fragment>
        {'Dieser Auftrag verweist auf die Staffing-AÜV aus '}
        <Link to={`/auftrag/${rahmenvertrag.auev.auftragsnummer}`}>
          {`Auftrag ${rahmenvertrag.auev.auftragsnummer}`}
        </Link>
        {'.'}
      </React.Fragment>
    );
  } else if (vertragsart === 'kissAuev') {
    text = `Dieser Auftrag verweist auf die KISS-AÜV mit der Nummer ${rahmenvertrag.kissAuev.vertragsnummer}.`;
  } else if (vertragsart === 'fremdvertrag') {
    const vertragsdatum = rahmenvertrag.fremdvertrag.vertragsdatum;
    const formattedVertragsdatum = formatDate(new window.Date(vertragsdatum));
    text = `Dieser Auftrag verweist auf einen Fremdvertrag mit dem Vertragsdatum ${formattedVertragsdatum}.`;
  } else if (vertragsart === 'youngProfessionalsVertrag') {
    text = `Dieser Auftrag verweist auf den Young-Professionals-Vertrag mit der Nummer ${rahmenvertrag.youngProfessionalsVertrag.vertragsnummer}.`;
  }

  return <p>{text}</p>;
};

export default (props) => (
  <Card>
    <h2 className="h2 mb-6">AÜV-Verweis</h2>
    <AktuellerVerweis
      auftragsnummer={props.auftragsdetails.auftragsnummer}
      rahmenvertrag={props.auftragsdetails.rahmenvertrag}
    />
    <hr className="my-5" />
    <VerweisAnpassen auftragsnummer={props.auftragsdetails.auftragsnummer} />
  </Card>
);
