import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from '../Login';
import CustomRoute from '../CustomRoute';
import Dashboard from '../Dashboard';
import Auftragsdetails from '../Auftragsdetails';
import Layout from '../DefaultLayout';

export default () => (
  <Switch>
    <CustomRoute
      isPrivate
      exact
      layout={Layout}
      path="/dashboard"
      component={Dashboard}
    />
    <CustomRoute
      isPrivate
      exact
      layout={Layout}
      path="/auftrag/:auftragsnummer"
      component={Auftragsdetails}
    />
    <Route exact path="/login" component={Login} />
    <Redirect from="/" to="/dashboard" />
  </Switch>
);
