import React from 'react';
import PropTypes from 'prop-types';

const Time = props => {
  if (!props.time) return null;

  let hours, minutes;
  [hours, minutes] = props.time.split(':');

  return (
    <span>
      <time>
        {hours}:{minutes}
      </time>{' '}
      Uhr
    </span>
  );
};

Time.propTypes = {
  time: PropTypes.string.isRequired
};

export default Time;
