import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import AlertContext from '../../../Alert/Context';
const AuftragsdetailsQuery = loader('../../AuftragsdetailsQuery.graphql');
const VerbindeUevMitKissAuevMutation = loader(
  './VerbindeUevMitKissAuevMutation.graphql'
);

export default ({ auftragsnummer }) => {
  const initialVertragsnummer = '';

  const [state, setState] = useState({
    vertragsnummer: initialVertragsnummer,
  });

  const { showAlert } = useContext(AlertContext);

  const [verbindeUevMitKissAuev] = useMutation(VerbindeUevMitKissAuevMutation, {
    update(cache, { data: { verbindeUevMitKissAuev } }) {
      cache.writeQuery({
        query: AuftragsdetailsQuery,
        variables: {
          auftragsnummer: parseInt(auftragsnummer),
        },
        data: { auftragsdetails: verbindeUevMitKissAuev },
      });
    },
  });

  const handleChange = (event) => {
    setState({
      vertragsnummer: event.target.value,
    });
  };

  const isValid = !!state.vertragsnummer && state.vertragsnummer.length <= 4;
  const showError = !isValid && state.vertragsnummer.length > 0;

  const resetForm = () => {
    setState({
      vertragsnummer: initialVertragsnummer,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verbindeUevMitKissAuev({
      variables: {
        uevAuftragsnummer: parseInt(auftragsnummer),
        vertragsnummer: state.vertragsnummer,
      },
    })
      .then(({ data }) => {
        const neueVertragsnummer =
          data.verbindeUevMitKissAuev.rahmenvertrag.kissAuev.vertragsnummer;

        showAlert({
          message: `Dieser Auftrag verweist nun auf die KISS-AÜV mit der Nummer ${neueVertragsnummer}.`,
          type: 'success',
        });
      })
      .finally(resetForm);
  };

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="kiss_verweis_form_vertragsnummer">
          KISS-Vertragsnummer
        </label>
        <input
          id="kiss_verweis_form_vertragsnummer"
          type="number"
          className={`form-control ${showError ? 'is-invalid' : ''}`}
          value={state.vertragsnummer}
          onChange={handleChange}
          min="0"
          max="9999"
          required
        />
        {showError ? (
          <div className="invalid-feedback mt-2">
            Bitte gib eine höchstens vierstellige Nummer ein.
          </div>
        ) : null}
      </div>
      <button
        type="submit"
        className="btn btn-primary w-auto"
        disabled={!isValid}
      >
        Speichern
      </button>
    </form>
  );
};
