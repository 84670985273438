import React from 'react';
import Context from './Context';
import styles from './Alert.module.scss';

export default props => (
  <Context.Consumer>
    {({ message, type, visible }) => {
      if (!message || !type) return null;

      const visibleClass = visible ? styles.visible : styles.invisible;

      return (
        <div className={`${styles.wrapper} position-absolute w-100 pt-4`}>
          <div className="d-flex align-items-center">
            <div
              className={`alert alert-${type} py-4 rounded m-auto position-relative ${styles.alert} ${visibleClass}`}
              role="alert"
            >
              <div className="container">{message}</div>
            </div>
          </div>
        </div>
      );
    }}
  </Context.Consumer>
);
