import SecureLS from 'secure-ls';

const secret = process.env.REACT_APP_LOCAL_STORAGE_SECRET;

if (!secret) {
  throw new Error('secret is missing');
}

export default new SecureLS({
  encodingType: 'aes',
  encryptionSecret: secret
});
