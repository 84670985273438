import { format } from 'date-fns';

const formatNumber = (number, { decimals } = { decimals: true }) => {
  if (!decimals) return Math.round(number).toString();

  let integerPart, fractionalPart;
  const roundedValue = Math.round(number * 100) / 100;

  [integerPart, fractionalPart] = roundedValue.toString().split('.');
  fractionalPart = (fractionalPart || '').padEnd(2, '0');

  return `${integerPart},${fractionalPart}`;
};

const formatDate = date => {
  if (date instanceof Date === false)
    throw new Error('Not an instance of window.Date');

  return format(date, 'dd.MM.yyyy');
};

const formatDateTime = datetime => {
  if (datetime instanceof Date === false)
    throw new Error('Not an instance of window.Date');

  return format(datetime, "dd.MM.yyyy HH:mm 'Uhr'");
};

const formatEuro = euro => {
  if (euro === undefined || euro === null) return null;

  return `${formatNumber(euro)} €`;
};

const formatHoursAndMinutes = minutes => {
  if (minutes === undefined || minutes === null) return null;

  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;

  if (hours && minutesLeft) {
    return `${hours} Stunden und ${minutesLeft} Minuten`;
  } else if (hours) {
    return `${hours} Stunden`;
  } else if (minutesLeft) {
    return `${minutesLeft} Minuten`;
  } else {
    return '0 Minuten';
  }
};

const formatHours = (hours, { decimals } = { decimals: true }) => {
  if (hours === undefined || hours === null) return null;

  return `${formatNumber(hours, { decimals })} Stunden`;
};

const formatDays = (days, { decimals } = { decimals: true }) => {
  if (days === undefined || days === null) return null;

  return `${formatNumber(days, { decimals })} Tage`;
};

const formatPercent = (percent, { decimals } = { decimals: true }) => {
  if (percent === undefined || percent === null) return null;

  return `${formatNumber(percent, { decimals })} %`;
};

export {
  formatDate,
  formatDateTime,
  formatEuro,
  formatHours,
  formatDays,
  formatPercent,
  formatNumber,
  formatHoursAndMinutes
};
