import React from 'react';
import Context from './Context';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: undefined,
      type: undefined
    };

    this.showAlert = ({ message, type }) => {
      if (this.state.timeout) clearTimeout(this.state.timeout);

      this.setState({
        message,
        type,
        visible: true,
        timeout: setTimeout(this.hideAlert, 10000)
      });
    };

    this.hideAlert = () => {
      this.setState({
        visible: false
      });
    };
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          showAlert: this.showAlert
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
