import { loader } from 'graphql.macro';
const NewDashboardItemSubscription = loader(
  './NewDashboardItemSubscription.graphql'
);

export default subscribeToMore => {
  subscribeToMore({
    document: NewDashboardItemSubscription,
    updateQuery(prev, { subscriptionData }) {
      if (!subscriptionData.data) return prev;

      const newEdge = {
        node: subscriptionData.data.newDashboardItem,
        __typename: 'DashboardItemEdge'
      };

      return Object.assign({}, prev, {
        dashboardItems: {
          __typename: prev.dashboardItems.__typename,
          edges: [
            newEdge,
            ...prev.dashboardItems.edges.filter(
              e => e.node.id !== newEdge.node.id
            )
          ],
          pageInfo: prev.dashboardItems.pageInfo
        }
      });
    }
  });
};
