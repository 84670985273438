import React from 'react';
import {
  formatEuro,
  formatPercent,
  formatHoursAndMinutes
} from '../../lib/Localization';
import { centToEuro } from '../../lib/Currency';
import Time from '../Time';

const GiltVonBis = props => {
  if (props.giltVon === props.giltBis) {
    return 'ganztags';
  } else {
    return (
      <React.Fragment>
        <Time time={props.giltVon} /> bis <Time time={props.giltBis} />
      </React.Fragment>
    );
  }
};

const NameZeile = props => {
  if (props.name) {
    return (
      <React.Fragment>
        <u>{props.name}</u>
        <br />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const GiltVonBisZeile = props => {
  if (props.giltVon && props.giltBis) {
    return (
      <React.Fragment>
        <GiltVonBis giltVon={props.giltVon} giltBis={props.giltBis} />
        <br />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const GiltAbZeile = props => {
  if (props.giltAbDauerInMinuten) {
    return (
      <React.Fragment>
        {`ab ${formatHoursAndMinutes(props.giltAbDauerInMinuten)}`}
        <br />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const KonsequenzZeile = props => (
  <React.Fragment>
    {(props.wertInProzent && formatPercent(props.wertInProzent)) ||
      (props.wertInCent && formatEuro(centToEuro(props.wertInCent)))}
  </React.Fragment>
);

export default props => (
  <React.Fragment>
    <NameZeile name={props.zuschlag.name} />
    <GiltVonBisZeile
      giltVon={props.zuschlag.voraussetzung.giltVon}
      giltBis={props.zuschlag.voraussetzung.giltBis}
    />
    <GiltAbZeile
      giltAbDauerInMinuten={props.zuschlag.voraussetzung.giltAbDauerInMinuten}
    />
    <KonsequenzZeile {...props.zuschlag.konsequenz} />
  </React.Fragment>
);
