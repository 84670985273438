import React from 'react';
import Card from '../../Card';
import LegalAPI from '../../../lib/LegalAPI';
import Kundenportal from '../../../lib/Kundenportal';
import { formatDateTime } from '../../../lib/Localization';
import DefinitionGroup from '../DefinitionGroup';
import NeueVersionErstellenButton from './NeueVersionErstellenButton';

const propToDatetimeString = (prop) => {
  return prop && formatDateTime(new Date(prop));
};

const erstelltAmText = (erstelltAm, auftragIsActive) =>
  erstelltAm
    ? propToDatetimeString(erstelltAm)
    : erstelltAmTextFallback(auftragIsActive);

const erstelltAmTextFallback = (auftragIsActive) =>
  auftragIsActive
    ? 'Neue Version wird erstellt'
    : 'Neue Version wird erstellt, sobald der Auftrag verlängert wurde';

const dokumentTypAlsText = (typ) => {
  switch (typ) {
    case 'auev':
      return 'AÜV';
    case 'uev':
      return 'ÜV';
    case 'mal':
      return 'Mitarbeiterliste';
    default:
      return null;
  }
};

const Version = ({ auftragsnummer, dokument }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="mr-3">{dokument.version}</span>
      <NeueVersionErstellenButton
        auftragsnummer={auftragsnummer}
        dokumentId={dokument.id}
      />
    </div>
  );
};

const DokumentSection = ({
  dokument,
  auftragsnummer,
  auevVertragsnummer,
  uevVertragsnummer,
  zendeskSellId,
  auftragIsActive,
}) => {
  if (!dokument) return null;
  const linkZumKundenportal =
    Kundenportal.vertragsverwaltungVertragssucheEndpoint({
      vertragstyp: dokument.typ,
      auftragsnummer,
      auevVertragsnummer,
      uevVertragsnummer,
    });

  return (
    <section>
      <h3 className="mb-3">{dokumentTypAlsText(dokument.typ)}</h3>
      <a
        className="d-inline-block mb-3 mr-4"
        href={LegalAPI.dokumenteEndpoint(dokument.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
      {linkZumKundenportal ? (
        <a
          className="d-inline-block mb-3"
          href={linkZumKundenportal}
          target="_blank"
          rel="noopener noreferrer"
        >
          Im Kundenportal öffnen
        </a>
      ) : null}
      <dl className="mb-0">
        <DefinitionGroup
          term="Version"
          definition={
            <Version auftragsnummer={auftragsnummer} dokument={dokument} />
          }
          margin="2"
        />
        <DefinitionGroup
          term="Erstellt am"
          definition={erstelltAmText(dokument.erstelltAm, auftragIsActive)}
          margin="2"
        />
        {dokument.typ === 'uev' ? (
          <DefinitionGroup
            term="Vertragsnummer"
            definition={auevVertragsnummer}
            margin="2"
          />
        ) : null}
      </dl>
    </section>
  );
};

export default ({
  dokumente,
  auftragsnummer,
  auevVertragsnummer,
  uevVertragsnummer,
  zendeskSellId,
  auftragIsActive,
}) => {
  return (
    <Card>
      <h2 className="h2 mb-5">Dokumente</h2>
      <div>
        {dokumente.map((d, index) => (
          <React.Fragment key={index}>
            {index === 0 ? '' : <hr className="my-5" />}
            <DokumentSection
              dokument={d}
              auftragsnummer={auftragsnummer}
              auevVertragsnummer={auevVertragsnummer}
              uevVertragsnummer={uevVertragsnummer}
              zendeskSellId={zendeskSellId}
              auftragIsActive={auftragIsActive}
            />
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
};
