import React from 'react';
import styles from './AsideNav.module.scss';
import LogoutButton from '../LogoutButton';

export default () => (
  <aside>
    <nav className={`${styles.nav} h-100`}>
      <ul className="list-unstyled p-0 h-100">
        <li className="h-100 d-flex align-items-center">
          <LogoutButton />
        </li>
      </ul>
    </nav>
  </aside>
);
