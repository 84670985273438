import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Auftragssuche.module.scss';

const handleSubmitFunction = history => {
  return function(event) {
    const inputElement = event.target.elements[0];
    const inputValue = inputElement.value;

    event.preventDefault();
    inputElement.value = '';
    history.push(`/auftrag/${inputValue}`);
  };
};

const Auftragssuche = props => (
  <form
    className="d-none d-md-flex align-items-center"
    onSubmit={handleSubmitFunction(props.history)}
  >
    <input
      type="number"
      className={`${styles.input} form-control mr-2 rounded-lg`}
      placeholder="Auftragsnummer"
    />
    <button className={`${styles.button} btn btn-brand rounded-lg`}>Los</button>
  </form>
);

export default withRouter(Auftragssuche);
