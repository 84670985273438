import React from 'react';
import styles from './DashboardItem.module.scss';
import ActionCard from '../ActionCard';
import { formatDate } from '../../lib/Localization';
import Aenderung from '../Aenderung';

const aenderungenMaxCount = 5;

const buildEinsatzort = ({ strasse, hausnummer, plz, stadt }) => {
  if (!(strasse && hausnummer)) return null;

  return (
    <address>
      {strasse} {hausnummer}
      <br />
      {plz} {stadt}
    </address>
  );
};

const DefinitionGroup = props => (
  <div>
    <dt className="mb-2">{props.term}</dt>
    <dd>{props.definition}</dd>
  </div>
);

const weitereAenderungenText = count =>
  count > 1 ? `${count} weitere Änderungen` : `Eine weitere Änderung`;

const Aenderungen = ({ aenderungen }) => {
  const additionalAenderungenCount = aenderungen.length - aenderungenMaxCount;

  return (
    <React.Fragment>
      <ul className="list-unstyled">
        {aenderungen.slice(0, aenderungenMaxCount).map((aenderung, index) => (
          <li
            className={index < aenderung.length - 1 ? 'mb-2' : ''}
            key={index}
          >
            <Aenderung aenderung={aenderung} showDetails={false} />
          </li>
        ))}
      </ul>
      {additionalAenderungenCount > 0 ? (
        <div className="small mt-2">
          {weitereAenderungenText(additionalAenderungenCount)}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const auftragsdetailsUrl = auftragsnummer => `/auftrag/${auftragsnummer}`;

export default ({ dashboardItem }) => (
  <ActionCard href={auftragsdetailsUrl(dashboardItem.auftragsnummer)}>
    <h1 className="custom-styles-font-size-lg text-nowrap text-primary mr-6">
      {dashboardItem.auftragsnummer}
    </h1>
    <dl className={`${styles.descriptionList} w-100 pb-3`}>
      <DefinitionGroup
        term="Änderungen"
        definition={<Aenderungen aenderungen={dashboardItem.aenderungen} />}
      />
      <DefinitionGroup term="Kunde" definition={dashboardItem.kundenname} />
      <DefinitionGroup
        term="Einsatzort"
        definition={buildEinsatzort(dashboardItem)}
      />
      <DefinitionGroup
        term="Einsatzbeginn"
        definition={
          dashboardItem.einsatzbeginn &&
          formatDate(new window.Date(dashboardItem.einsatzbeginn))
        }
      />
    </dl>
  </ActionCard>
);
